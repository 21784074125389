<template>
    <div>
      <el-form :model="form" ref="form">
        <el-form-item label="年日均活期存款">
          <el-input v-model.number="form.annualDepositPerDay" type="number">
            <span slot="suffix" >万元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="年日均活期存款客户经理端模拟利润利率">
          <el-input v-model.number="form.annualDepositPerDayRate" type="number">
            <span slot="suffix" >%</span>
          </el-input>
        </el-form-item>
        <el-form-item label="年日均协定存款">
          <el-input v-model.number="form.annualAgreementDepositPerDay" type="number">
            <span slot="suffix" >万元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="年日均协定存款客户经理端模拟利润利率">
          <el-input v-model.number="form.annualAgreementDepositPerDayRate" type="number">
            <span slot="suffix" >%</span>
          </el-input>
        </el-form-item>
        <el-form-item label="其他业务模拟利润">
          <el-input v-model.number="form.otherSimulatedProfits" type="number">
            <span slot="suffix" >万元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="年交易量">
          <el-input v-model.number="form.annualTradingVolume" type="number">
            <span slot="suffix" >万元</span>
          </el-input>
        </el-form-item>
        <el-button type="primary" @click="doCalc" size="large" style="width:100%">
          计算
        </el-button>
      </el-form>
  
      <div>
        <el-row :gutter="20" style="margin:10px -10px;">
          <el-col :span="24">
            <el-card shadow="hover" style="width: 100%;">
              <div style="width: 100%;">
                <el-statistic :value="this.form.ysbRate" title="易收宝费率">
                  <template slot="suffix">
                    ‰
                  </template>
                </el-statistic>
              </div>
            </el-card>
          </el-col>
        </el-row>

      </div>
    </div>
  </template>
  
  <script>
  import { round } from 'mathjs';
  
  export default {
    name: "YsbCalculator",
    data() {
      return {
        form: {
          annualDepositPerDay: 0,
          annualDepositPerDayRate: 1.8,
          annualAgreementDepositPerDay: 0,
          annualAgreementDepositPerDayRate: 0.7,
          otherSimulatedProfits: 0,
          annualTradingVolume: null,
          ysbRate: null,
          costRate: 2 // 成本费率，千分之二
        },
        totalAmount: 0
      }
    },
    methods: {
      doCalc() {
        
        this.$nextTick(() => {
          console.log("年日均活期存款收益", this.form.annualDepositPerDay * this.form.annualDepositPerDayRate / 100)
          console.log("年日均协定存款收益", this.form.annualAgreementDepositPerDay * this.form.annualAgreementDepositPerDayRate / 100)
          console.log("其他业务收益", this.form.otherSimulatedProfits)
          console.log("所有收益", ((this.form.annualDepositPerDay * this.form.annualDepositPerDayRate / 100) 
                                   + (this.form.annualAgreementDepositPerDay * this.form.annualAgreementDepositPerDayRate / 100)
                                   + this.form.otherSimulatedProfits))
          // 根据存款收益和其他业务收益，计算能补贴给客户的费率
          // 计算公式：（年日均活期存款余额*年日均活期存款利率 + 年日均协定存款存款 * 年日均协定存款利率 + 其他业务模拟利润）/ 年交易量 * 1000‰
          const ysbBtRate = round(((this.form.annualDepositPerDay * this.form.annualDepositPerDayRate / 100) 
                                   + (this.form.annualAgreementDepositPerDay * this.form.annualAgreementDepositPerDayRate / 100)
                                   + this.form.otherSimulatedProfits) / this.form.annualTradingVolume * 1000, 2)
          // this.form.ysbRate = ysbBtRate > 2 ? 2 : (ysbBtRate > 0 ? ysbBtRate : 0)
          // 如果成本费率减去补贴费率大于0，说明补贴费率无法覆盖成本，需要向客户收取易收宝费率；若小于0，说明补贴费率可以覆盖成本，不需要向客户收取费率。
          this.form.ysbRate = (this.form.costRate - ysbBtRate) > 0? round((this.form.costRate - ysbBtRate), 2) : 0
          // this.form.maxRateAmount = round(this.form.totalAmount * (this.form.standardRate - this.form.minRate)/(this.form.maxRate - this.form.minRate), 2)
          console.log(ysbBtRate, this.form, this.form.ysbRate)
        })
      }
    }
  }
  </script>
  
  <style>
  #app {
    font-family: Helvetica, sans-serif;
    text-align: center;
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  .el-form-item__label{
    line-height: 30px;
  }
  .el-statistic .con span {
    line-height: 1.5em;
  }
  </style>
  