<template>
  <div id="app">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="小微贷错配计算器" name="first">
        <xwd-calculator />
      </el-tab-pane>
      <el-tab-pane label="易收宝费率计算器" name="second">
        <ysb-calculator />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import XwdCalculator from './components/XwdCalculator.vue';
import YsbCalculator from './components/YsbCalculator.vue';
export default {
  name: 'App',
  components: {
    XwdCalculator,
    YsbCalculator
  },
  data() {
    return {
      activeName: 'first'
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
