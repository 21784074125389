<template>
    <div>
      <el-form :model="form" ref="form">
        <el-form-item label="目标利率">
          <el-input v-model.number="form.standardRate" type="number">
            <span slot="suffix" >%</span>
          </el-input>
        </el-form-item>
        <el-form-item label="最低利率">
          <el-input v-model.number="form.minRate" type="number">
            <span slot="suffix" >%</span>
          </el-input>
        </el-form-item>
        <el-form-item label="最高利率">
          <el-input v-model.number="form.maxRate" type="number">
            <span slot="suffix" >%</span>
          </el-input>
        </el-form-item>
        <el-form-item label="贷款金额">
          <el-input v-model.number="form.totalAmount" type="number">
            <span slot="suffix" >万元</span>
          </el-input>
        </el-form-item>
        <el-button type="primary" @click="doCalc" size="large" style="width:100%">
          计算
        </el-button>
      </el-form>
  
      <div>
        <el-row :gutter="20" style="margin:10px -10px;">
          <el-col :span="24">
            <el-card shadow="hover" style="width: 100%;">
              <div style="width: 100%;">
                <el-statistic :value="totalAmount" :title="'利率' + form.standardRate + '%'">
                  <template slot="suffix">
                    万元
                  </template>
                </el-statistic>
              </div>
            </el-card>
          </el-col>
        </el-row>
  
        <el-row :gutter="20" style="margin:10px -10px;">
          <el-col :span="24">
            <el-card shadow="hover" style="width: 100%;">
              <div style="width: 100%;">
                <el-statistic :value="form.minRateAmount" :title="'利率' + form.minRate + '%'">
                  <template slot="suffix">
                    万元
                  </template>
                </el-statistic>
              </div>
            </el-card>
          </el-col>
        </el-row>
  
        <el-row :gutter="20" style="margin:10px -10px;">
          <el-col :span="24">
            <el-card shadow="hover" style="width: 100%;">
              <div style="width: 100%;">
                <el-statistic :value="form.maxRateAmount" :title="'利率' + form.maxRate + '%'">
                  <template slot="suffix">
                    万元
                  </template>
                </el-statistic>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </template>
  
  <script>
  import { round } from 'mathjs';
  
  export default {
    name: "XwdCalculator",
    data() {
      return {
        form: {
          standardRate: 5,
          minRate: 3.5,
          maxRate: 6,
          totalAmount: null,
          minRateAmount: 0,
          maxRateAmount: 0
        },
        totalAmount: 0
      }
    },
    methods: {
      doCalc() {
        
        this.$nextTick(() => {
          this.totalAmount = this.form.totalAmount * 1
          this.form.minRateAmount = round(this.form.totalAmount * (this.form.standardRate - this.form.maxRate)/(this.form.minRate - this.form.maxRate), 2)
          this.form.maxRateAmount = round(this.form.totalAmount * (this.form.standardRate - this.form.minRate)/(this.form.maxRate - this.form.minRate), 2)
          console.log(this.form.minRateAmount, this.form.maxRateAmount)
        })
      }
    }
  }
  </script>
  
  <style>
  #app {
    font-family: Helvetica, sans-serif;
    text-align: center;
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  .el-form-item__label{
    line-height: 30px;
  }
  </style>
  